
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {mdiDotsVertical, mdiDownload} from "@mdi/js";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import moment from "moment";
import axios from "axios";

@Component
export default class Home extends Vue {
  moment = moment
  $message:any
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "currency",
  });

  quoteDataSource = new ListDataSource({
    className:"AssetQuote",
    config:{
      filter:JSON.stringify([
        new Filter("baseAssetId", +this.$route.params.id)
      ])
    }
  })

  icons: any = {
    dotsVertical: mdiDotsVertical,
    download:mdiDownload
  };

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Валюта",
      value: "quoteAsset",
      sortable: true,
    },
    {
      text: "Единица",
      value: "unit",
      sortable: true,
    },
    {
      text: "Курс",
      value: "value",
      sortable: false,
    },
    {
      text: "Дата обнавления",
      value: "date",
      sortable: true,
    },

  ];

  get caption(){
    return this.dataSource.model?.name;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Валюты",
        to: "/manage/currencies/",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async refreshRates(){
    await axios.post(`manage/currency/${this.$route.params.id}/update/1/`);
    await this.quoteDataSource.get();
    this.$message("Данные о курсах успешно обнавлены");
  }
}
